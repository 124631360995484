import { useParams } from 'react-router-dom';
import TEENPATTI2020 from '../20-20-TEENPATTI'
import Lucky7 from '../lucky7';
import DRAGONTIGER2020 from '../20-20 DRAGON TIGER'
import ANDARBAHARA from '../ANDAR BAHAR - A'
import CARDS32A from '../32 CARDS - A'
import TEENPATTI1DAY from '../1-DAY TEENPATTI'
import BACCARAT from '../BACCARAT'
import POKERA2020 from '../POKERA2020'
import DRAGONTIGER from '../DRAGONTIGER'
import DRAGONTIGER1DAY from '../DRAGONTIGER1DAY'


const Casino = () => {
    const { id } = useParams();

    switch (id) {
        case '1':
            return <TEENPATTI2020 />;
        case '2':
            return <Lucky7 />;
        case '3':
            return <DRAGONTIGER2020 />;
        case '5':
            return <ANDARBAHARA />;
        case '6':
            return <CARDS32A />;
        case '8':
            return <TEENPATTI1DAY />;
        case '4':
            return <BACCARAT />;
        case '7':
            return <POKERA2020 />;
        case '9':
            return <DRAGONTIGER />;
        case '10':
            return <DRAGONTIGER1DAY />;
        default:
            return <div>Casino not found</div>;
    }
};

export default Casino;