import '../../App.css'
import { useEffect, useState } from 'react';
import { LuClock9 } from "react-icons/lu";
import { SlScreenDesktop } from "react-icons/sl";
import { Link, useParams } from 'react-router-dom';
import { signal } from "@preact/signals-react";
import HorseRacingSchedule from '../../components/horseRacingSchedule';
import Cricket from '../../components/cricket';
import Tennis from '../../components/tennis';
import Soccer from '../../components/soccer';
import GreyhoundRacingSchedule from '../../components/greyhoundRacingSchedule';
import Politics from '../../components/politics';
import Lottery from '../../components/lottery';
import News from '../../components/news';

export const eventId = signal("");
function AllSportHighlights() {
    const { id } = useParams();
    // eventId.value = id;
    const userInfo = JSON.parse(localStorage.getItem('userdata'))

    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:grid grid-cols-12 ' >
                {userInfo && (
                    <div className='col-span-12 leading-[15px]  bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)]'>
                        <h4 className='z-10 text-[12px] font-bold relative bg-[linear-gradient(-180deg,_#274253_0%,_#0f191f_82%)] text-[#ffffff] pl-[9px] pr-[4px] py-[4px] inline-block'>
                            <i className="fa fa-microphone"></i>
                            <span>&nbsp;News</span>
                        </h4>
                        <marquee direction="left" scrollamount="5" behavior="scroll" className='w-[90%] text-[#ffffff] inline-table font-bold text-[12px] leading-[21px]'><News /></marquee>
                    </div>
                )}
                <div className='col-span-12 !h-[192.41px] w-full'>
                    <img src='/universe-casino.jpeg' className='!h-[192.41px] w-full' />
                </div>
                <div className={`col-span-12 px-[10px] py-[3px] bg-[linear-gradient(-180deg,_#0000d0_0,_#010281_100%)] ${id == 66104 && 'hidden'}`}>
                    <h3 className='text-[0.75rem] text-black  font-bold'>Highlights</h3>
                </div>
                <div className='col-span-12'>
                    <div className="grid grid-cols-12 gap-6">
                        <div className='col-span-12'>
                            {id == 4 && (
                                <Cricket />

                            )}

                            {id == 2 && (
                                <Tennis />
                            )}
                            {id == 1 && (
                                <Soccer />
                            )}
                            {
                                id == 7 && (
                                    <HorseRacingSchedule />
                                )
                            }
                            {
                                id == 4339 && (
                                    <GreyhoundRacingSchedule />
                                )
                            }
                            {
                                id == 2378961 && (
                                    <Politics />
                                )
                            }
                            {
                                id == 66104 && (
                                    <Lottery />
                                )
                            }
                        </div>


                        <div className='col-span-12 '>
                            <div className="justify-center flex items-center border-t-[1px] border-[#0000004d] mt-[25px] mb-[29px] pt-[1.5rem]">
                                <ul className=" flex flex-wrap items-center gap-3 text-[0.875rem] font-medium text-black ">
                                    <li className="border-r-[1.2px] border-black leading-4">
                                        <a href="#" className="me-4 ">Privacy Policy</a>
                                    </li>

                                    <li className="border-r-[1.2px] border-black leading-4">
                                        <a href="#" className="me-4 ">KYC</a>
                                    </li>

                                    <li className="border-r-[1.2px] border-black leading-4">
                                        <a href="#" className="me-4 ">Terms and Conditions</a>
                                    </li>

                                    <li className="border-r-[1.2px] border-black leading-4">
                                        <a href="#" className="me-4 ">Rules and Regulations</a>
                                    </li>

                                    <li className="border-black leading-4">
                                        <a href="#" className="me-4 ">Responsible Gambling</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div >



            {/* Mobile */}
            <div div className='grid lg:hidden grid-col-12 relative top-[56px]' >
                <div className={`col-span-12 ${[7, 4339].includes(Number(id)) && 'hidden'}`}>
                    <img src='/universe-casino.jpeg' className='w-full' />
                </div>
                <div className={`col-span-12 px-[10px] py-[3px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] ${[7, 4339].includes(Number(id)) && 'hidden'}`}>
                    <h3 className='text-[0.75rem] text-white  text-center font-bold'>Highlights</h3>
                </div>
                <div className={`col-span-12  overflow-x-auto ${[2, 4, 1, 2378961].includes(Number(id)) && 'max-h-[260px]'}`}>
                    {
                        id == 2 && (
                            <Tennis />
                        )}
                    {
                        id == 4 && (
                            <Cricket />
                        )}
                    {
                        id == 1 && (
                            <Soccer />
                        )}
                    {
                        id == 7 && (
                            <HorseRacingSchedule />
                        )
                    }
                    {
                        id == 4339 && (
                            <GreyhoundRacingSchedule />
                        )
                    }
                    {
                        id == 2378961 && (
                            <Politics />
                        )
                    }
                    {
                        id == 66104 && (
                            <Lottery />
                        )
                    }
                </div>


                <div className='col-span-12 '>
                    <div className=" border-t-[1px] border-[#0000004d]  pt-[1.5rem]">
                        <ul className="text-center text-[0.875rem] font-medium text-black ">
                            <li className="inline  leading-4">
                                <a href="#" className="">Privacy Policy</a>
                            </li>

                            <li className="inline  leading-4">
                                <a href="#" className="line">KYC</a>
                            </li>

                            <li className="inline  leading-4">
                                <a href="#" className="line">Terms and Conditions</a>
                            </li>

                            <li className="inline  leading-4">
                                <a href="#" className="line ">Rules and Regulations</a>
                            </li>

                            <li className="inline  leading-4">
                                <a href="#" className="line">Responsible Gambling</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex items-center justify-center mb-[100px] pt-[1.5rem]">
                        <svg className='w-[155px] h-[44px]' width="128" height="40" viewBox="0 0 128 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M124 40H4C1.8 40 0 38.2 0 36V4C0 1.8 1.8 0 4 0H124C126.2 0 128 1.8 128 4V36C128 38.2 126.2 40 124 40Z" fill="black" />
                            <path d="M27 19.8C27 16.7 29.6 15.2 29.7 15.1C28.2 13 26 12.7 25.2 12.6C23.3 12.4 21.4 13.7 20.5 13.7C19.5 13.7 18 12.6 16.4 12.6C14.3 12.6 12.4 13.8 11.3 15.7C9.10001 19.5 10.7 25.1 12.8 28.2C13.9 29.7 15.1 31.4 16.7 31.3C18.3 31.2 18.9 30.3 20.8 30.3C22.7 30.3 23.2 31.3 24.9 31.3C26.6 31.3 27.7 29.8 28.7 28.3C29.9 26.6 30.4 24.9 30.4 24.8C30.3 24.7 27 23.5 27 19.8Z" fill="white" />
                            <path d="M23.9001 10.6001C24.7001 9.5001 25.3001 8.1001 25.2001 6.6001C24.0001 6.7001 22.4001 7.4001 21.6001 8.5001C20.8001 9.4001 20.1001 10.9001 20.3001 12.3001C21.6001 12.4001 23.0001 11.6001 23.9001 10.6001Z" fill="white" />
                            <path d="M49.8 31.0999H47.6L46.4 27.2999H42.2L41.1 31.0999H39L43.2 18.2H45.8L49.8 31.0999ZM46 25.7L44.9 22.2999C44.8 21.9999 44.6 21.1 44.2 19.9C44.1 20.4 43.9 21.2999 43.6 22.2999L42.5 25.7H46Z" fill="white" />
                            <path d="M60.5 26.4C60.5 28 60.1 29.2 59.2 30.2C58.4 31 57.5 31.4 56.3 31.4C55.1 31.4 54.2 30.9999 53.7 30.0999V35H51.6V25C51.6 24 51.6 23 51.5 21.9H53.3L53.4 23.4C54.1 22.3 55.1 21.7 56.5 21.7C57.6 21.7 58.5 22.1 59.2 23C60.1 23.8 60.5 24.9 60.5 26.4ZM58.4 26.4C58.4 25.5 58.2 24.7 57.8 24.2C57.4 23.6 56.8 23.2999 56 23.2999C55.5 23.2999 55 23.4999 54.6 23.7999C54.2 24.0999 53.9 24.5999 53.8 25.0999C53.7 25.3999 53.7 25.6 53.7 25.7V27.2999C53.7 27.9999 53.9 28.5 54.3 29C54.7 29.5 55.3 29.7 55.9 29.7C56.7 29.7 57.3 29.3999 57.7 28.7999C58.2 28.1999 58.4 27.4 58.4 26.4Z" fill="white" />
                            <path d="M71.2002 26.4C71.2002 28 70.8002 29.2 69.9002 30.2C69.1002 31 68.2002 31.4 67.0002 31.4C65.8002 31.4 64.9002 30.9999 64.4002 30.0999V35H62.3002V25C62.3002 24 62.3002 23 62.2002 21.9H64.0002L64.1002 23.4C64.8002 22.3 65.8002 21.7 67.2002 21.7C68.3002 21.7 69.2002 22.1 69.9002 23C70.8002 23.8 71.2002 24.9 71.2002 26.4ZM69.1002 26.4C69.1002 25.5 68.9002 24.7 68.5002 24.2C68.1002 23.6 67.5002 23.2999 66.7002 23.2999C66.2002 23.2999 65.7002 23.4999 65.3002 23.7999C64.9002 24.0999 64.6002 24.5999 64.5002 25.0999C64.4002 25.3999 64.4002 25.6 64.4002 25.7V27.2999C64.4002 27.9999 64.6002 28.5 65.0002 29C65.4002 29.5 66.0002 29.7 66.6002 29.7C67.4002 29.7 68.0002 29.3999 68.4002 28.7999C68.9002 28.1999 69.1002 27.4 69.1002 26.4Z" fill="white" />
                            <path d="M83.1998 27.5001C83.1998 28.6001 82.7998 29.5001 82.0998 30.2001C81.2998 31.0001 80.0998 31.3001 78.5998 31.3001C77.1998 31.3001 76.0998 31.0001 75.2998 30.5001L75.7998 28.8001C76.6998 29.3001 77.6998 29.6001 78.7998 29.6001C79.5998 29.6001 80.1998 29.4001 80.5998 29.1001C80.9998 28.7001 81.1998 28.3001 81.1998 27.7001C81.1998 27.2001 80.9998 26.7001 80.6998 26.4001C80.2998 26.0001 79.6998 25.7001 78.8998 25.4001C76.5998 24.6001 75.4998 23.3001 75.4998 21.7001C75.4998 20.6001 75.8998 19.8001 76.6998 19.1001C77.4998 18.4001 78.4998 18.1001 79.8998 18.1001C81.0998 18.1001 81.9998 18.3001 82.7998 18.7001L82.2998 20.4001C81.5998 20.0001 80.7998 19.8001 79.7998 19.8001C79.0998 19.8001 78.4998 20.0001 78.0998 20.3001C77.7998 20.6001 77.5998 21.0001 77.5998 21.5001C77.5998 22.0001 77.7998 22.4001 78.1998 22.8001C78.4998 23.1001 79.1998 23.4001 80.0998 23.8001C81.1998 24.2001 81.9998 24.8001 82.5998 25.4001C82.8998 25.9001 83.1998 26.6001 83.1998 27.5001Z" fill="white" />
                            <path d="M89.9998 23.4H87.6998V27.9C87.6998 29 88.0998 29.6 88.8998 29.6C89.2998 29.6 89.5998 29.6 89.7998 29.5L89.8998 31.1C89.4998 31.3 88.9998 31.3 88.2998 31.3C87.4998 31.3 86.7998 31.1 86.3998 30.6C85.8998 30.1 85.6998 29.3 85.6998 28.1V23.4H84.2998V21.8H85.6998V20.1L87.6998 19.5V21.8H89.9998V23.4Z" fill="white" />
                            <path d="M100.3 26.4001C100.3 27.8001 99.9002 29.0001 99.1002 29.9001C98.2002 30.8001 97.1002 31.3001 95.7002 31.3001C94.3002 31.3001 93.2002 30.8001 92.4002 29.9001C91.6002 29.0001 91.2002 27.9001 91.2002 26.5001C91.2002 25.1001 91.6002 23.9001 92.5002 23.0001C93.3002 22.1001 94.5002 21.6001 95.9002 21.6001C97.3002 21.6001 98.4002 22.1001 99.2002 23.0001C99.9002 23.9001 100.3 25.0001 100.3 26.4001ZM98.1002 26.5001C98.1002 25.6001 97.9002 24.9001 97.5002 24.3001C97.1002 23.6001 96.4002 23.2001 95.6002 23.2001C94.8002 23.2001 94.1002 23.6001 93.7002 24.3001C93.3002 24.9001 93.1002 25.7001 93.1002 26.5001C93.1002 27.4001 93.3002 28.1001 93.7002 28.7001C94.1002 29.4001 94.8002 29.8001 95.6002 29.8001C96.4002 29.8001 97.0002 29.4001 97.5002 28.7001C97.9002 28.1001 98.1002 27.3001 98.1002 26.5001Z" fill="white" />
                            <path d="M107 23.6C106.8 23.6 106.6 23.5 106.3 23.5C105.6 23.5 105 23.8 104.6 24.3C104.3 24.8 104.1 25.4 104.1 26.1V31H102V24.6C102 23.5 102 22.6 101.9 21.7H103.7L103.8 23.5H103.9C104.1 22.9 104.5 22.4 104.9 22C105.4 21.7 105.9 21.5 106.4 21.5C106.6 21.5 106.8 21.5 106.9 21.5V23.6H107Z" fill="white" />
                            <path d="M116.2 26.0001C116.2 26.4001 116.2 26.7001 116.1 26.9001H109.9C109.9 27.8001 110.2 28.5001 110.8 29.0001C111.3 29.4001 112 29.7001 112.8 29.7001C113.7 29.7001 114.6 29.6001 115.3 29.3001L115.6 30.7001C114.7 31.1001 113.7 31.3001 112.5 31.3001C111.1 31.3001 109.9 30.9001 109.1 30.0001C108.3 29.2001 107.9 28.0001 107.9 26.6001C107.9 25.2001 108.3 24.0001 109 23.1001C109.8 22.1001 110.9 21.6001 112.3 21.6001C113.6 21.6001 114.7 22.1001 115.3 23.1001C116 23.9001 116.2 24.9001 116.2 26.0001ZM114.3 25.5001C114.3 24.9001 114.2 24.4001 113.9 23.9001C113.5 23.3001 113 23.0001 112.3 23.0001C111.6 23.0001 111.1 23.3001 110.7 23.8001C110.4 24.2001 110.2 24.8001 110.1 25.4001H114.3V25.5001Z" fill="white" />
                            <path d="M45.4002 10.3C45.4002 11.4 45.1002 12.3 44.4002 12.9C43.8002 13.4 42.9002 13.7001 41.7002 13.7001C41.1002 13.7001 40.6002 13.7 40.2002 13.6V7.40005C40.7002 7.30005 41.3002 7.30005 42.0002 7.30005C43.1002 7.30005 43.9002 7.50005 44.5002 8.00005C45.0002 8.50005 45.4002 9.30005 45.4002 10.3ZM44.3002 10.3C44.3002 9.60005 44.1002 9.00005 43.7002 8.60005C43.3002 8.20005 42.7002 8.00005 42.0002 8.00005C41.7002 8.00005 41.4002 8.00005 41.2002 8.10005V12.8C41.3002 12.8 41.6002 12.8 41.9002 12.8C42.7002 12.8 43.3002 12.6001 43.7002 12.2001C44.1002 11.8001 44.3002 11.1 44.3002 10.3Z" fill="white" />
                            <path d="M51 11.2999C51 11.9999 50.8 12.5999 50.4 12.9999C50 13.4999 49.4 13.6999 48.7 13.6999C48 13.6999 47.5 13.4999 47.1 12.9999C46.7 12.5999 46.5 11.9999 46.5 11.2999C46.5 10.5999 46.7 9.9999 47.1 9.5999C47.5 9.0999 48.1 8.8999 48.8 8.8999C49.5 8.8999 50 9.0999 50.4 9.5999C50.8 9.9999 51 10.5999 51 11.2999ZM50 11.2999C50 10.8999 49.9 10.4999 49.7 10.1999C49.5 9.7999 49.2 9.6999 48.8 9.6999C48.4 9.6999 48.1 9.8999 47.9 10.1999C47.7 10.4999 47.6 10.8999 47.6 11.2999C47.6 11.6999 47.7 12.0999 47.9 12.3999C48.1 12.7999 48.4 12.8999 48.8 12.8999C49.2 12.8999 49.5 12.6999 49.7 12.2999C49.9 12.0999 50 11.6999 50 11.2999Z" fill="white" />
                            <path d="M58.7 9L57.3 13.6H56.4L55.8 11.6C55.6 11.1 55.5 10.6 55.4 10.1C55.3 10.6 55.2 11.1 55 11.6L54.4 13.6H53.5L52 9H53L53.5 11.2C53.6 11.7 53.7 12.2 53.8 12.7C53.9 12.3 54 11.8 54.2 11.2L54.8 9H55.6L56.2 11.1C56.4 11.6 56.5 12.1 56.6 12.6C56.7 12.1 56.8 11.6 56.9 11.1L57.5 9H58.7Z" fill="white" />
                            <path d="M63.9 13.6H62.9V11C62.9 10.2 62.6 9.80005 62 9.80005C61.7 9.80005 61.5 9.90005 61.3 10.1C61.1 10.3 61 10.6 61 10.9V13.6H60V10.3C60 9.90005 60 9.50005 60 9.00005H60.9V9.70005C61 9.50005 61.2 9.30005 61.4 9.10005C61.7 8.90005 62 8.80005 62.3 8.80005C62.7 8.80005 63.1 8.90005 63.4 9.20005C63.8 9.50005 63.9 10.0001 63.9 10.7001V13.6Z" fill="white" />
                            <path d="M66.7002 13.5999H65.7002V6.8999H66.7002V13.5999Z" fill="white" />
                            <path d="M72.7002 11.2999C72.7002 11.9999 72.5002 12.5999 72.1002 12.9999C71.7002 13.4999 71.1002 13.6999 70.4002 13.6999C69.7002 13.6999 69.2002 13.4999 68.8002 12.9999C68.4002 12.5999 68.2002 11.9999 68.2002 11.2999C68.2002 10.5999 68.4002 9.9999 68.8002 9.5999C69.2002 9.1999 69.8002 8.8999 70.5002 8.8999C71.2002 8.8999 71.7002 9.0999 72.1002 9.5999C72.5002 9.9999 72.7002 10.5999 72.7002 11.2999ZM71.7002 11.2999C71.7002 10.8999 71.6002 10.4999 71.4002 10.1999C71.2002 9.7999 70.9002 9.6999 70.5002 9.6999C70.1002 9.6999 69.8002 9.8999 69.6002 10.1999C69.4002 10.4999 69.3002 10.8999 69.3002 11.2999C69.3002 11.6999 69.4002 12.0999 69.6002 12.3999C69.8002 12.7999 70.1002 12.8999 70.5002 12.8999C70.9002 12.8999 71.2002 12.6999 71.4002 12.2999C71.6002 12.0999 71.7002 11.6999 71.7002 11.2999Z" fill="white" />
                            <path d="M77.6004 13.5999H76.7004L76.6004 13.0999C76.3004 13.4999 75.8004 13.6999 75.3004 13.6999C74.9004 13.6999 74.5004 13.5999 74.3004 13.2999C74.1004 12.9999 73.9004 12.6999 73.9004 12.3999C73.9004 11.7999 74.1004 11.3999 74.6004 11.0999C75.1004 10.7999 75.7004 10.6999 76.6004 10.6999V10.5999C76.6004 9.9999 76.3004 9.6999 75.7004 9.6999C75.2004 9.6999 74.9004 9.7999 74.5004 9.9999L74.3004 9.2999C74.7004 8.9999 75.2004 8.8999 75.9004 8.8999C77.1004 8.8999 77.7004 9.4999 77.7004 10.7999V12.4999C77.6004 12.9999 77.6004 13.2999 77.6004 13.5999ZM76.6004 11.9999V11.2999C75.5004 11.2999 74.9004 11.5999 74.9004 12.1999C74.9004 12.3999 75.0004 12.5999 75.1004 12.6999C75.2004 12.7999 75.4004 12.8999 75.6004 12.8999C75.8004 12.8999 76.0004 12.7999 76.2004 12.6999C76.4004 12.5999 76.5004 12.3999 76.6004 12.1999C76.6004 12.1999 76.6004 12.0999 76.6004 11.9999Z" fill="white" />
                            <path d="M83.3996 13.6H82.4996V12.9C82.1996 13.5 81.6996 13.7 80.9996 13.7C80.3996 13.7 79.9996 13.5 79.5996 13.1C79.1996 12.7 79.0996 12.1 79.0996 11.4C79.0996 10.7 79.2996 10.1 79.6996 9.6C80.0996 9.2 80.5996 9 81.0996 9C81.6996 9 82.0996 9.2 82.3996 9.6V7H83.3996V12.4C83.3996 12.8 83.3996 13.2 83.3996 13.6ZM82.3996 11.7V10.9C82.3996 10.8 82.3996 10.7 82.3996 10.6C82.2996 10.4 82.1996 10.2 81.9996 10C81.7996 9.8 81.5996 9.8 81.2996 9.8C80.8996 9.8 80.5996 10 80.3996 10.3C80.1996 10.6 80.0996 11 80.0996 11.5C80.0996 12 80.1996 12.3 80.3996 12.6C80.5996 12.9 80.8996 13.1 81.2996 13.1C81.5996 13.1 81.8996 13 82.0996 12.7C82.2996 12.3 82.3996 12 82.3996 11.7Z" fill="white" />
                            <path d="M92.0996 11.2999C92.0996 11.9999 91.8996 12.5999 91.4996 12.9999C91.0996 13.4999 90.4996 13.6999 89.7996 13.6999C89.0996 13.6999 88.5996 13.4999 88.1996 12.9999C87.7996 12.5999 87.5996 11.9999 87.5996 11.2999C87.5996 10.5999 87.7996 9.9999 88.1996 9.5999C88.5996 9.0999 89.1996 8.8999 89.8996 8.8999C90.5996 8.8999 91.0996 9.0999 91.4996 9.5999C91.8996 9.9999 92.0996 10.5999 92.0996 11.2999ZM90.9996 11.2999C90.9996 10.8999 90.8996 10.4999 90.6996 10.1999C90.4996 9.7999 90.1996 9.6999 89.7996 9.6999C89.3996 9.6999 89.0996 9.8999 88.8996 10.1999C88.6996 10.4999 88.5996 10.8999 88.5996 11.2999C88.5996 11.6999 88.6996 12.0999 88.8996 12.3999C89.0996 12.7999 89.3996 12.8999 89.7996 12.8999C90.1996 12.8999 90.4996 12.6999 90.6996 12.2999C90.8996 12.0999 90.9996 11.6999 90.9996 11.2999Z" fill="white" />
                            <path d="M97.6002 13.6H96.6002V11C96.6002 10.2 96.3002 9.80005 95.7002 9.80005C95.4002 9.80005 95.2002 9.90005 95.0002 10.1C94.8002 10.3 94.7002 10.6 94.7002 10.9V13.6H93.7002V10.3C93.7002 9.90005 93.7002 9.50005 93.7002 9.00005H94.6002V9.70005C94.7002 9.50005 94.9002 9.30005 95.1002 9.10005C95.4002 8.90005 95.7002 8.80005 96.0002 8.80005C96.4002 8.80005 96.8002 8.90005 97.1002 9.20005C97.5002 9.50005 97.6002 10.0001 97.6002 10.7001V13.6Z" fill="white" />
                            <path d="M104.4 9.7999H103.3V11.9999C103.3 12.5999 103.5 12.7999 103.9 12.7999C104.1 12.7999 104.2 12.7999 104.4 12.7999V13.5999C104.2 13.6999 103.9 13.6999 103.6 13.6999C103.2 13.6999 102.9 13.5999 102.7 13.2999C102.5 13.0999 102.4 12.5999 102.4 12.0999V9.7999H101.7V8.9999H102.4V8.1999L103.4 7.8999V8.9999H104.5V9.7999H104.4Z" fill="white" />
                            <path d="M109.8 13.5999H108.8V10.9999C108.8 10.1999 108.5 9.7999 107.9 9.7999C107.4 9.7999 107.1 9.9999 106.9 10.4999C106.9 10.5999 106.9 10.6999 106.9 10.8999V13.5999H105.9V6.8999H106.9V9.6999C107.2 9.1999 107.7 8.8999 108.3 8.8999C108.7 8.8999 109.1 8.9999 109.3 9.2999C109.6 9.5999 109.8 10.1999 109.8 10.7999V13.5999Z" fill="white" />
                            <path d="M115.3 11.1C115.3 11.3 115.3 11.4 115.3 11.6H112.3C112.3 12.1 112.5 12.4 112.7 12.6C113 12.8 113.3 12.9 113.7 12.9C114.2 12.9 114.6 12.8 114.9 12.7L115.1 13.4C114.7 13.6 114.2 13.7 113.6 13.7C112.9 13.7 112.3 13.5 111.9 13.1C111.5 12.7 111.3 12.1 111.3 11.4C111.3 10.7 111.5 10.1 111.9 9.7C112.3 9.2 112.8 9 113.5 9C114.2 9 114.7 9.2 115 9.7C115.2 10 115.3 10.5 115.3 11.1ZM114.4 10.8C114.4 10.5 114.3 10.2 114.2 10C114 9.7 113.8 9.6 113.4 9.6C113.1 9.6 112.8 9.7 112.6 10C112.4 10.2 112.3 10.5 112.3 10.8H114.4Z" fill="white" />
                        </svg>
                    </div >
                </div >


            </div>
        </>
    );
}

export default AllSportHighlights;
