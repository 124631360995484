import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import LeftSidebar from './components/leftSidebar';
import RightSidebar from './components/rightSidebar';
import { useState } from 'react';
import { LuClock9 } from "react-icons/lu";
import { SlScreenDesktop } from "react-icons/sl";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Inplay from './pages/inplay';
import AllSportHighlights from './pages/allsport-highlight';
import FullMarket from './pages/fullMarket';
import MyProfile from './pages/myprofile';
import RollingCommission from './pages/rollingCommission';
import AccountStatement from './pages/accountStatement';
import BetHistory from './pages/betHistory';
import ProfitLoss from './pages/profit-loss';
import ProfitLossEvents from './pages/profit-loss-events';
import ProfitLossEventMarkets from './pages/profit-loss-event-markets';
import ProfitLossBetHistory from './pages/profit-loss-bet-history';

import PasswordHistory from './pages/password-history';
import ActivityLog from './pages/activityLog';
import MultiMarket from './pages/multimarket';
import Account from './pages/account';
import LiveCasino from './pages/live-casino';
import TipsPreview from './pages/tips-previews';
import Sports from './pages/sports/index';
import VirtualSports from './pages/virtual-sports';
import Setting from './pages/setting';
// import Casino from './pages/casino';
// import TEENPATTI2020 from './pages/20-20-TEENPATTI'
// import Lucky7 from './pages/lucky7';
// import DRAGONTIGER2020 from './pages/20-20 DRAGON TIGER'
// import ANDARBAHARA from './pages/ANDAR BAHAR - A'
// import CARDS32A from './pages/32 CARDS - A'
// import TEENPATTI1DAY from './pages/1-DAY TEENPATTI'
// import BACCARAT from './pages/BACCARAT'
// import POKERA2020 from './pages/POKERA2020'
import Casino from './pages/Casino'


import { WebSocketContext, socket } from './context/websocket';
// import { CasinoWebSocketContext, casinoSocket } from './context/casinoWebsocket';

function App() {

  const [activeTab, setActiveTab] = useState('cricket')
  const [activeCasino, setActiveCasino] = useState('popular')
  const [selectedGame, setSelectedGame] = useState('cricket')
  return (
    <WebSocketContext.Provider value={socket}>
      {/* <CasinoWebSocketContext.Provider value={casinoSocket}> */}
      <Routes>
        <Route index element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/inplay' element={<Inplay />} />
        <Route path='/allsport-highlight/:id' element={<AllSportHighlights />} />
        <Route path='/fullMarket/:event_id/:is_inplay' element={<FullMarket />} />
        <Route path='/casino/:id' element={<Casino />} />

        {/* <Route path='/casino/1' element={<TEENPATTI2020 />} />
        <Route path='/casino/2' element={<Lucky7 />} />
        <Route path='/casino/3' element={<DRAGONTIGER2020 />} />
        <Route path='/casino/5' element={<ANDARBAHARA />} />
        <Route path='/casino/6' element={<CARDS32A />} />
        <Route path='/casino/8' element={<TEENPATTI1DAY />} />
        <Route path='/casino/4' element={<BACCARAT />} />
        <Route path='/casino/7' element={<POKERA2020 />} /> */}


        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/rolling-commission' element={<RollingCommission />} />
        <Route path='/account-statement' element={<AccountStatement />} />
        <Route path='/bet-history' element={<BetHistory />} />
        <Route path='/profit-loss' element={<ProfitLoss />} />
        <Route path='/profit-loss-event/:event_type_id/:from_date/:to_date' element={<ProfitLossEvents />} />
        <Route path='/profit-loss-event-market/:match_id/:from_date/:to_date' element={<ProfitLossEventMarkets />} />
        <Route path='/profit-loss-bet-history/:match_id/:market_id/:from_date/:to_date' element={<ProfitLossBetHistory />} />

        <Route path='/password-history' element={<PasswordHistory />} />
        <Route path='/activity-log' element={<ActivityLog />} />
        <Route path='/multimarket' element={<MultiMarket />} />
        <Route path='/account' element={<Account />} />
        <Route path='/live-casino' element={<LiveCasino />} />
        <Route path='/tips-previews' element={<TipsPreview />} />
        <Route path='/sports' element={<Sports />} />
        <Route path='/virtual-sports' element={<VirtualSports />} />
        <Route path='/setting' element={<Setting />} />

      </Routes>
      {/* </CasinoWebSocketContext.Provider> */}
    </WebSocketContext.Provider>
  );
}

export default App;
